<template>
  <template class="flex flex-col gap-4">
    <section class="box p-4 flex justify-between">
      <div class="flex flex-col">
        <span class="font-bold">Numero de radicado:</span>
        <span class="text-lg">{{ getProcesoJudicial.numeroRadicado }}</span>
      </div>

      <div class="flex flex-col">
        <span class="font-bold">Información del juzgado:</span>
<!--        <span class="text-lg">{{ getProcesoJudicial.juzgadoProcesosJudiciales.localizacion }}</span>-->
                <span class="text-lg">{{ getProcesoJudicial.juzgadoProcesosJudiciales.categoria.nombre }} {{ getProcesoJudicial.juzgadoProcesosJudiciales.code }} {{ getProcesoJudicial.juzgadoProcesosJudiciales.nombre }} DE {{ getProcesoJudicial.juzgadoProcesosJudiciales.localizacion }}</span>
      </div>

      <div class="flex flex-col justify-center gap-2">
        <div class="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full" viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"/>
          </svg>
          <span class="font-bold">Fecha Reparto: </span>
          <span>{{ $h.formatDate(getProcesoJudicial.fechaReparto) }}</span>
        </div>
        <div class="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full" viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"/>
          </svg>
          <span class="font-bold">Fecha Notificación: </span>
          <span>{{ $h.formatDate(getProcesoJudicial.fechaNotificacion) }}</span>
        </div>
      </div>
    </section>

    <section class="flex flex-col lg:flex-row w-full gap-4">
      <div class="flex flex-col w-full gap-4">
        <div class="box p-4">
          <span class="font-bold">Informacion Demandados</span>
          <div class="flex flex-col" v-for="(item, key) in getProcesoJudicial.accionadosProcesosJudiciales" :key="key">
            <span>{{ key + 1 }}. {{ item.accionado.fullname }}</span>
            <span>{{ item.accionado.docTipoId }}. {{ item.accionado.numeroIdentificacion }}</span>
            <template v-if="item.accionado.apoderadoNombre !== null">
              <span>Apoderado: {{ item.accionado.apoderadoNombre }}</span>
              <span>{{ item.accionado.apoderadoTipoDoc }}. {{ item.accionado.apoderadoDocumento }}</span>
            </template>
          </div>
        </div>

        <div class="box p-4 flex flex-col gap-4">
          <div class="flex justify-between">
            <span class="font-bold">Soportes</span>
            <div v-tooltip="'Agregar soporte'" @click="openMaximizable()">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" preserveAspectRatio="xMidYMid meet"
                   viewBox="0 0 1024 1024"
                   class="cursor-pointer text-blue-600"
              >
                <path fill="currentColor"
                      d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM544 472c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v108H372c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h108v108c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h108c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V472z"/>
              </svg>
            </div>
          </div>

          <div style="max-height: 22rem;" class="overflow-y-auto grid grid-cols-1"
               v-if="getProcesoJudicial.paths.length > 0">
            <div v-for="(soporte, i) in getProcesoJudicial.paths" :key="i">
              <div class="flex justify-between my-2">
                <div class="flex">
                  <i class="pi pi-file-pdf mr-2 text-red-600"></i>
                  <p>{{ soporte.fileName }}</p>
                </div>
                <div>
                  <div class="flex">
                    <button @click="verPdf(soporte.urlPath)" class="ease-in duration-300">
                      <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;"
                           class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span v-else>
            No hay soportes
          </span>
        </div>

        <div class="box p-4 flex flex-col" v-if="getProcesoJudicial.medidaCautelar">
          <span class="font-bold">Medida Cautelar</span>
          <span>{{ getProcesoJudicial.descripcionMedidaCautelar }}</span>
        </div>
      </div>

      <div class="flex flex-col w-full gap-4">

        <div class="box p-4 flex flex-col">
          <span class="font-bold">Solicitud</span>
          <span>{{ getProcesoJudicial.observaciones }}</span>
        </div>

        <div class="box p-4 flex flex-col">
          <span class="font-bold">Ultima Actuación</span>
          <template v-if="getProcesoJudicial.actuacionesAssociated.length > 0">
            <span>-<span class="font-medium">Anotación:</span> {{
                getProcesoJudicial.actuacionesAssociated[0].anotacion
              }}</span>
            <span>-<span
              class="font-medium">Observaciones:</span> {{ getProcesoJudicial.actuacionesAssociated[0].observaciones }}</span>
            <span>-<span class="font-medium">Fecha Actuación:</span> {{
                getProcesoJudicial.actuacionesAssociated[0].fechaActuacion
              }}</span>
            <span>-<span class="font-medium">Fecha Inicia Termino:</span> {{
                getProcesoJudicial.actuacionesAssociated[0].fechaIniciaTermino
              }}</span>
            <span>-<span class="font-medium">Fecha Finaliza Termino:</span> {{
                getProcesoJudicial.actuacionesAssociated[0].fechaFinalizaTermino
              }}</span>
          </template>
          <template v-else>
            <span>No hay actuaciones asociadas</span>
          </template>
        </div>

        <div class="box p-4 flex flex-col">
          <template class="flex items-center justify-between">
            <span class="font-bold">Agenda</span>
            <div class="flex gap-2">
              <div v-tooltip.top="'Crear'">
                <svg @click="displayMaximizable = true" class="w-6 h-6 cursor-pointer" fill="none" stroke="currentColor"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
              <div v-tooltip.top="'Historial'" @click="openHistorial()">
                <svg class="w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"></path>
                </svg>
              </div>
            </div>
          </template>
          <template v-if="getProcesoJudicial.agendaAssociated.length > 0">
            <div v-for="(item, key) in getProcesoJudicial.agendaAssociated" :key="key" class="flex flex-col">
              <span>-<span class="font-medium">Fecha:</span> {{ $h.formatDate(item.fecha, 'DD-MM-YYYY HH:mm') }}</span>
              <span>-<span class="font-medium">Observaciones:</span> {{ item.observaciones }}</span>
              <span v-if="item.url">-<span class="font-medium">Url:</span> <a class="text-blue-500 hover:underline"
                                                                              :href="item.url"
                                                                              target="_blank">{{ item.url }}</a></span>
              <span>-<span class="font-medium">Estado:</span> <Tag
                :value="( dayjs().isBefore(dayjs(item.fecha).format('DD-MM-YYYY HH:mm')) ) ? 'Cumplido' : 'Pendiente'"
                :severity="dayjs().isBefore(dayjs(item.fecha).format('DD-MM-YYYY HH:mm')) ? 'success' : 'danger'"/></span>
              <div class="border-t-2 mt-2 mb-2"
                   v-if="getProcesoJudicial.agendaAssociated.length > 1 && key < (getProcesoJudicial.agendaAssociated.length - 1)"></div>
            </div>
          </template>
          <template v-else>
            <span>No hay agendas asociadas</span>
          </template>
        </div>
      </div>
    </section>
  </template>

  <Dialog header="Agregar Agenda" v-model:visible="displayMaximizable"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col w-full">
        <span class="text-xs">Fecha Agenda:</span>
        <input type="datetime-local" v-model="model.fecha" class="w-full p-inputtext"/>
        <MessageError :text="errors.fecha"/>
      </div>
      <div class="flex flex-col w-full">
        <span class="text-xs">Observaciones:</span>
        <Textarea v-model="model.observaciones" :autoResize="true" rows="3" cols="30"/>
        <MessageError :text="errors.observaciones"/>
      </div>
      <div class="flex flex-col w-full">
        <span class="text-xs">Link:</span>
        <InputText v-model="model.url" class="w-full p-inputtext"/>
        <MessageError :text="errors.url"/>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable()" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Historial"
          v-model:visible="displayHistorial"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{ width: '25vw' }"
          :modal="true">
    <template v-if="historial.count > 0">
      <div v-for="(item, i) in historial.rows" :key="i" class="flex flex-col">
        <span>-<span class="font-medium">Fecha:</span> {{ $h.formatDate(item.fecha, 'DD-MM-YYYY HH:mm') }}</span>
        <span>-<span class="font-medium">Observaciones:</span> {{ item.observaciones }}</span>
        <span v-if="item.url">-<span class="font-medium">Url:</span> <a class="text-blue-500 hover:underline"
                                                                        :href="item.url" target="_blank">{{
            item.url
          }}</a></span>
        <span>-<span class="font-medium">Estado:</span> <Tag
          :value="( dayjs().isBefore(dayjs(item.fecha)) ) ? 'Pendiente' : 'Cumplido'"
          :severity="dayjs().isBefore(dayjs(item.fecha)) ? 'danger' : 'success'"/></span>
        <div class="border-t-2 mt-2 mb-2" v-if="historial.count > 1 && i < (historial.count - 1)"></div>
      </div>
    </template>
    <template class="text-center" #footer>
      <Button label="Cerrar" class="w-full" @click="closeHistorial()" autofocus/>
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import ProcesosJudicialesStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/procesosJudiciales.store'
import { useRoute } from 'vue-router'
import PdfService from '../../../../../services/pdf.service'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { useField, useForm } from 'vee-validate'
import AgendaProcesosJudicialesService
  from '@/apps/pharmasan/juridica/procesosJuridicos/services/agendaProcesosJudiciales.service'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'info-proceso',
  setup () {
    const _AgendaProcesosJudicialesService = new AgendaProcesosJudicialesService()
    const route = useRoute()
    const displayMaximizable = ref(false)
    const modalAgregarSoportes = ref(false)
    const displayHistorial = ref(false)
    const historial = ref([])
    const openMaximizable = () => {
      modalAgregarSoportes.value = true
    }

    const getProcesoJudicial = computed(() => ProcesosJudicialesStore.getters.getProcesoJudicial)
    const _PdfService = new PdfService()

    const schema = yup.object({
      fecha: yup.date().nullable().min(dayjs().subtract(1, 'day'), 'La fecha de actuación debe ser hoy o posterior').required('El campo es requerido').label(''),
      observaciones: yup.string().nullable().required('El campo es requerido').label(''),
      url: yup.string().nullable()
    })
    const { errors, values: model, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('fecha', null, { initialValue: null })
    useField('observaciones', null, { initialValue: null })
    useField('url', null, { initialValue: null })

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const closeMaximizable = handleSubmit(async (values) => {
      values.procesoJudicialId = route.params.id
      await _AgendaProcesosJudicialesService.save(values)
      await ProcesosJudicialesStore.dispatch('actionProcesoJudicial', route.params.id)
      displayMaximizable.value = false
      handleReset()
    })

    const closeHistorial = () => {
      displayHistorial.value = false
      historial.value = []
    }

    const openHistorial = async () => {
      await _AgendaProcesosJudicialesService.paginate({
        filters: {
          procesoJudicialId: {
            value: route.params.id,
            matchMode: FilterMatchMode.EQUALS
          }
        }
      }).then(({ data }) => {
        historial.value = data
      })
      displayHistorial.value = true
    }

    onMounted(() => {
      ProcesosJudicialesStore.dispatch('actionProcesoJudicial', route.params.id)
    })

    return {
      getProcesoJudicial,
      displayMaximizable,
      errors,
      model,
      closeMaximizable,
      displayHistorial,
      historial,
      verPdf,
      dayjs,
      openHistorial,
      closeHistorial,
      openMaximizable,
      modalAgregarSoportes
    }
  }
}
</script>

<style scoped>

</style>
