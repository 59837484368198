import http from '../../../../../libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class AgendaProcesosJudicialesService {
  paginate (payload) {
    return http.get(`${baseUrl}/agenda-procesos-judiciales/paginate`, {
      params: payload,
      headers: {
        loading: false
      }
    })
  }

  save (payload) {
    return http.post(`${baseUrl}/agenda-procesos-judiciales`, payload, {
      headers: {
        loading: false
      }
    })
  }
}
